import React from "react";
import theme from "theme";
import { Theme, Text, Link, Icon, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsArrowRightShort } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar>
			<Override slot="quarklycommunityKitMenuOverride" />
		</Components.TopNavBar>
		<Section padding="88px 0 88px 0" min-height="100vh" quarkly-title="404-1">
			<Override slot="SectionContent" max-width="1220px" justify-content="center" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--primary">
					404
				</Text>
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
				>
					Page not found
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 40px 0px"
					text-align="center"
				>
					Sorry, we couldn’t find the page you’re looking for.
				</Text>
				<Box display="grid" grid-template-columns="1fr 1fr" grid-gap="8px" sm-grid-template-columns="1fr">
					<Link
						font="--lead"
						padding="12px 24px 12px 24px"
						background="--color-primary"
						border-radius="8px"
						href="/"
						text-decoration-line="initial"
						color="--light"
						transition="--opacityOut"
						hover-opacity="0.7"
					>
						Go back to home
					</Link>
					<LinkBox
						flex-direction="row"
						padding="12px 6px 12px 8px"
						hover-background="--color-dark"
						hover-color="--light"
						transition="background-color --transitionDuration-normal --transitionTimingFunction-easeOut 0s"
						border-radius="8px"
						href="https://bosbalance.com/contact"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="inherit">
							Contact support{" "}
						</Text>
						<Icon category="bs" icon={BsArrowRightShort} size="24px" margin="4px 0px 0px 4px" />
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});